.wrapper {
  background-color: #fff3d8;
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 12px;
  padding: 10px 24px;
  position: absolute;
  top: 72px;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 4px 0px #b6b1d280;
}

.wrapper span {
  font-weight: 700;
}
.icon {
  margin-right: 5px;
}
.toolTipContainer {
  white-space: pre-line;
}
.infoIcon {
  font-size: 16px !important;
  color: rgba(39, 37, 34, 0.5);
  cursor: pointer;
  margin-left: 5px;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: auto;
    position: sticky;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1200px) {
  .wrapper {
    top: 97px;
  }
}
